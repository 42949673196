import { createNamespacedHelpers } from "vuex";

let modules = [];
let currentStore = null;

export default (moduleName, vuexModule) => {

    const registerModuleToStore = (store) => {
        if (currentStore !== store) {
            currentStore = store;
            modules = [];
        }

        const shouldRegisterModule = modules.indexOf(vuexModule) === -1 && !store.hasModule(moduleName);

        shouldRegisterModule && store.registerModule(moduleName, vuexModule);
    }

    const unregisterModuleFromStore = (store) => {
        const index = modules.indexOf(vuexModule);

        if (index === -1) return;

        modules.splice(index, 1);

        modules.indexOf(vuexModule) === -1 && store.unregisterModule(moduleName);
    }

    const RegisterVuexModuleMixin = {
        beforeCreate() {
            registerModuleToStore(this.$store);
        },
        beforeDestroy() {
            unregisterModuleFromStore(this.$store);
        }
    }

    return {
        registerModuleToStore,
        unregisterModuleFromStore,
        RegisterVuexModuleMixin,
        ...createNamespacedHelpers(moduleName),
        createNamespacedHelpersForSubmodule: (subModuleName) => createNamespacedHelpers(`${moduleName}/${subModuleName}`)
    }
}
